import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";

import {
  WaveFooter,
  Section,
  Content,
  EmailContainer,
  Headline,
  Text,
  TextLinkExternal,
} from "../components/SubComponents";

import { ContactLink, ImprintLine } from "../components/Atoms";

import { EMAIL_SARAH, LINK_FREEPIK } from "../constants/linkdata";

const VectorLink = styled(TextLinkExternal)`
  font-size: initial;
  padding: initial;
`;

const ImprintPage = ({ location: { pathname } }) => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <SEO title={t("Imprint")} path={pathname} />
      <Section id={"imprint"}>
        <Content>
          <Text>
            <Headline>
              <Trans>Imprint</Trans>
            </Headline>
            <ImprintLine space={1}>
              <Trans>Service provided by:</Trans>
            </ImprintLine>
            <ImprintLine>Sarah Kolb</ImprintLine>
            <ImprintLine>13 Kempshott Road</ImprintLine>
            <ImprintLine>SW16 5LG</ImprintLine>
            <ImprintLine space={2}>
              <Trans>London, United Kingdom</Trans>
            </ImprintLine>
            <ImprintLine>
              <Trans>
                For direct business enquiries, please contact me via email.
              </Trans>
            </ImprintLine>
            {/* prettier-ignore */}
            <ImprintLine space={2}>
              <Trans>Email:</Trans> <EmailContainer userString={EMAIL_SARAH.USER} mailString={EMAIL_SARAH.DOMAIN}></EmailContainer>
            </ImprintLine>
            <ImprintLine space={1}>
              <ContactLink to={"/contact"}>
                <Trans>Link to electronic contact</Trans>
              </ContactLink>
            </ImprintLine>
            {/* prettier-ignore */}
            <ImprintLine space={1}>
              <Trans>
                Vector graphics used on this page have been derived from assets by pch.vector, found on <VectorLink href={LINK_FREEPIK}>freepik.com</VectorLink>.
              </Trans>
            </ImprintLine>
            <ImprintLine space={4}>
              &copy; 2021 - {new Date().getFullYear()} {/* prettier-ignore */}
              <Trans>
                all rights reserved. Any reproduction or redistribution in any medium in whole or in part requires the written consent of Sarah Kolb.
              </Trans>
            </ImprintLine>
          </Text>
        </Content>
        <WaveFooter />
      </Section>
    </BaseLayout>
  );
};

export default ImprintPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "imprint"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
